<template>
    <div class="PlanCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" :inline="true" label-width="80px" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-input readonly v-model="form.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                v-model="form.settleDate"
                                style="width: 198px"
                                readonly
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-input readonly v-model="form.cashierName" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购买笔数">
                            <el-input readonly v-model="form.goodsCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="购买人数">
                            <el-input readonly v-model="form.memberCount" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="购买总额">
                            <el-input readonly v-model="detail.goodsSumMoney" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" readonly />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>

            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.plan.goods.dailySettle.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; overflow: auto" align="left">
            <el-row>
                <el-col :span="12" v-for="item in detail.details" :key="item">
                    <el-table
                        border
                        stripe
                        style="width: 100%; margin-bottom: 15px"
                        :data="item.settlePayDoList"
                        size="mini"
                        :highlight-current-row="true"
                        min-height="400px"
                    >
                        <el-table-column prop="planName" label="计划名称" width="180" />
                        <el-table-column prop="templateName" label="支付类型" width="130" />
                        <el-table-column prop="goodsSumMoney" label="应收总额" width="100">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.goodsSumMoney | money }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="paid" label="支付总额" width="100">
                            <template slot-scope="scope"
                                ><span>{{ scope.row.paid | money }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from '../../../components/PickupReturnAudit';

export default {
    name: 'GoodsPlanDailySettleCreate',
    components: { EfRemark, PickupReturnAudit },
    props: ['form'],
    data() {
        return {
            detail: {
                details: [],
                goodsSumMoney: '',
            },
            url: {
                baseUrl: '/merchandising/settle',
                queryDetail: '/merchandising/settle/settlePayInfo',
            },
            meta: {
                types: [],
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.url.baseUrl);
        },
        handleQuery() {
            const _this = this;
            if (_this.form.planCodes && _this.form.planCodes.length > 0) {
                for (const code of _this.form.planCodes) {
                    const temDetail = {};
                    temDetail.code = code;
                    temDetail.settlePayDoList = [];
                    _this.detail.details.push(temDetail);
                }
            }
            _this.detail.goodsSumMoney = _this.fmtMoney(_this.form.goodsSumMoney);
            const params = { params: { code: _this.form.code } };
            Util.queryTable(_this, _this.url.queryDetail, params, (data) => {
                _this.detail.details = data.data;
            });
        },
    },
};
</script>

<style scoped>
.dailySettle_detail .el-form-item {
    margin-bottom: 0;
}
</style>
